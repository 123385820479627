import React, { Component } from 'react'
import { connect } from 'react-redux'
import MyMsgList from '../msglist/MyMsgList'

export class Middle extends Component {

    render() {
        return (
            <div>
                <MyMsgList />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Middle)
