import React, { Component } from "react";
import MyMsgList from "../../components/msglist/MyMsgList";
import MyInput from "../../components/input/MyInput";

export class MsgList extends Component {
  render() {
    return (
      <div className="rightpanel">
        <div>
          <MyMsgList />
        </div>
        <MyInput className="myinput" />
      </div>
    );
  }
}

export default MsgList;
