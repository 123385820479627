import React, { Component } from 'react'
import { connect } from 'react-redux'

export class Right extends Component {

    render() {
        return (
            <div>
                safsa
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Right)
