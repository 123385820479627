import React, { Component } from "react";
import { Input } from "react-chat-elements";
import Button from "react-bootstrap/Button";

class MyInput extends Component {
  render() {
    return (
      <div className="myinput">
        <Input
          placeholder="Type here..."
          multiline={true}
          rightButtons={
            <Button color="white" backgroundColor="black" text="Send" />
          }
        />
      </div>
    );
  }
}

export default MyInput;
