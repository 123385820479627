import React, { Component } from "react";
import AppNavBar from "./nav/AppNavBar";
import "./Base.css";

class Base extends Component {
  render() {
    return (
      <div className="mynavbar grid-container">
        <AppNavBar className="grid-item" />
        {this.props.children}
      </div>
    );
  }
}

export default Base;
