import React, { Component } from 'react';
import { Nav, NavDropdown } from 'react-bootstrap';



class LoginButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: window.sessionStorage.user ? window.sessionStorage.user : "login"
        }
    };
    

    getLogStatusLable() {
        if (this.state.user === "login") {
            this.loginlabel = "log in";
        } else {
            this.loginlabel = "log out";
        }
        return this.loginlabel;
    };

    doLogOut() {
        if (this.state.user === "login") {
            window.location = "/login";
        } else {
            window.sessionStorage.removeItem("user");
            window.location = "/log";
        }
    }


    render() {
        return (<Nav id="loginbutton">
            <NavDropdown  title={this.state.user} id="basic-nav-dropdown">
                <NavDropdown.Item  onClick={() => this.doLogOut(this)}>{this.getLogStatusLable()}</NavDropdown.Item>
            </NavDropdown>
        </Nav>);
    };
};

export default LoginButton;
