import React, { Component } from 'react'
import { connect } from 'react-redux'
import { ChatList } from 'react-chat-elements'


export class MyChatList extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            dataSource :this.props.dataSource
        }
    }

    onClick =(x,i)=>{
        console.log(x,i)
    }
    
    
    render() {
        return (
            <div>
                <ChatList classNme='chatlist' dataSource = {this.state.dataSource} onClick={this.onClick}/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = {
    
}

export default connect(mapStateToProps, mapDispatchToProps)(MyChatList)
