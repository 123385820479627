import React, { Component } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import LoginButton from "./LoginButton";
import { withTranslation  } from "react-i18next";
import "./AppNavBar.css";

class AppNavBar extends Component {
  render() {
    return (
      <Navbar
        class="mynavbar"
        collapseOnSelect
        expand="lg"
        bg="dark"
        variant="dark"
      >
        <Navbar.Brand href="#home">Schat</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link href="/test">test</Nav.Link>
            <Nav.Link href="/chat">chat</Nav.Link>
            <Nav.Link href="/channel">channel</Nav.Link>
            <NavDropdown title="Actions" id="collasible-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">
                Create a channel
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Create a chat
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Nav>
            <Nav.Link href="/feedback">feedback</Nav.Link>
            <LoginButton />
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default withTranslation("navbar")(AppNavBar);
