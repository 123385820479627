import React, { Component } from "react";
import LeftChat from "./LeftChat";
import MsgList from "./MsgList";
import "./MyChat.css";
import { ChannelProvider } from "../../data/ChannelContext";

export class MyChat extends Component {
  render() {
    return (
      <ChannelProvider>
        <div className="mychat">
          {/* <div className="mychannel">1</div>
          <div className="mymsglist">2</div>
          <div>3</div> */}
          <LeftChat />

          <MsgList />
        </div>
      </ChannelProvider>
    );
  }
}

export default MyChat;
