import React, {  useContext } from "react";
import MyChatList from "../../components/chatlist/MyChatList";
import { ChannelContext } from "../../data/ChannelContext";

const LeftChat = () => {
  const [channels] = useContext(ChannelContext);

  return (
    <div className="mychannel">
      <MyChatList dataSource={channels} />
    </div>
  );
};

export default LeftChat;
