import React, { Component } from 'react'
import { connect } from 'react-redux'

export class Left extends Component {

    render() {
        return (
            <div>
                saasfsaf
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = {

}

export default connect(mapStateToProps, mapDispatchToProps)(Left)

