import React, { Component } from 'react'
import Left from "../../components/left/Left";
import Middle from "../../components/middle/Middle";
import Right from "../../components/right/Right";
import "./App.css"

export class App extends Component {

    render() {
        return (
            <div class="container-fluid">
            <div class="row">
                <div class="col-md-2 leftpanel" ><Left/></div>
                <div class="col-md-8 middlepanel"><Middle/> </div>
                <div  class="col-md-2 rightpanel"><Right/> </div>
            </div>
            </div>
        )
    }
}

export default App
