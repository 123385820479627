import React, { Component } from "react";
import "./t2.css";
class t2 extends Component {
  render() {
    return (
      <div class="c-container">
        <header>header</header>
        <main>content</main>
        <footer>footer</footer>
      </div>
    );
  }
}

export default t2;
