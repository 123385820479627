import React, { useState, createContext } from "react";

export const ChannelContext = createContext();

export const ChannelProvider = (props) => {
  const [channels, setChannels] = useState([
    {
      avatar: "https://facebook.github.io/react/img/logo.svg",
      alt: "Reactjs",
      title: "Facebook",
      subtitle: "What are you doing?",
      date: new Date(),
      unread: 0,
    },
    {
      avatar: "https://facebook.github.io/react/img/logo.svg",
      alt: "Reactjs",
      title: "Facebook",
      subtitle: "What are you doing?",
      date: new Date(),
      unread: 0,
    },
    {
      avatar: "https://facebook.github.io/react/img/logo.svg",
      alt: "Reactjs",
      title: "Facebook",
      subtitle: "What are you doing?",
      date: new Date(),
      unread: 0,
    },
    {
      avatar: "https://facebook.github.io/react/img/logo.svg",
      alt: "Reactjs",
      title: "Facebook",
      subtitle: "What are you doing?",
      date: new Date(),
      unread: 0,
    },
    {
      avatar: "https://facebook.github.io/react/img/logo.svg",
      alt: "Reactjs",
      title: "Facebook",
      subtitle: "What are you doing?",
      date: new Date(),
      unread: 0,
    },
    {
      avatar: "https://facebook.github.io/react/img/logo.svg",
      alt: "Reactjs",
      title: "Facebook",
      subtitle: "What are you doing?",
      date: new Date(),
      unread: 0,
    },
    {
      avatar: "https://facebook.github.io/react/img/logo.svg",
      alt: "Reactjs",
      title: "Facebook",
      subtitle: "What are you doing?",
      date: new Date(),
      unread: 0,
    },
    {
      avatar: "https://facebook.github.io/react/img/logo.svg",
      alt: "Reactjs",
      title: "Facebook",
      subtitle: "What are you doing?",
      date: new Date(),
      unread: 0,
    },
    {
      avatar: "https://facebook.github.io/react/img/logo.svg",
      alt: "Reactjs",
      title: "Facebook",
      subtitle: "What are you doing?",
      date: new Date(),
      unread: 0,
    },
    {
      avatar: "https://facebook.github.io/react/img/logo.svg",
      alt: "Reactjs",
      title: "Facebook",
      subtitle: "What are you doing?",
      date: new Date(),
      unread: 0,
    },
    {
      avatar: "https://facebook.github.io/react/img/logo.svg",
      alt: "Reactjs",
      title: "Facebook",
      subtitle: "What are you doing?",
      date: new Date(),
      unread: 0,
    },
    {
      avatar: "https://facebook.github.io/react/img/logo.svg",
      alt: "Reactjs",
      title: "Facebook",
      subtitle: "What are you doing?",
      date: new Date(),
      unread: 0,
    },
    {
      avatar: "https://facebook.github.io/react/img/logo.svg",
      alt: "Reactjs",
      title: "Facebook",
      subtitle: "What are you doing?",
      date: new Date(),
      unread: 0,
    },
    {
      avatar: "https://facebook.github.io/react/img/logo.svg",
      alt: "Reactjs",
      title: "Facebook",
      subtitle: "What are you doing?",
      date: new Date(),
      unread: 0,
    },
    {
      avatar: "https://facebook.github.io/react/img/logo.svg",
      alt: "Reactjs",
      title: "Facebook",
      subtitle: "What are you doing?",
      date: new Date(),
      unread: 0,
    },
    {
      avatar: "https://facebook.github.io/react/img/logo.svg",
      alt: "Reactjs",
      title: "Facebook",
      subtitle: "What are you doing?",
      date: new Date(),
      unread: 0,
    },
    {
      avatar: "https://facebook.github.io/react/img/logo.svg",
      alt: "Reactjs",
      title: "Facebook",
      subtitle: "What are you doing?",
      date: new Date(),
      unread: 0,
    },
    {
      avatar: "https://facebook.github.io/react/img/logo.svg",
      alt: "Reactjs",
      title: "Facebook",
      subtitle: "What are you doing?",
      date: new Date(),
      unread: 0,
    },
    {
      avatar: "https://facebook.github.io/react/img/logo.svg",
      alt: "Reactjs",
      title: "Facebook",
      subtitle: "What are you doing?",
      date: new Date(),
      unread: 0,
    },
    {
      avatar: "https://facebook.github.io/react/img/logo.svg",
      alt: "Reactjs",
      title: "Facebook",
      subtitle: "What are you doing?",
      date: new Date(),
      unread: 0,
    },
    {
      avatar: "https://facebook.github.io/react/img/logo.svg",
      alt: "Reactjs",
      title: "Facebook",
      subtitle: "What are you doing?",
      date: new Date(),
      unread: 0,
    },
    {
      avatar: "https://facebook.github.io/react/img/logo.svg",
      alt: "Reactjs",
      title: "Facebook",
      subtitle: "What are you doing?",
      date: new Date(),
      unread: 0,
    },
    {
      avatar: "https://facebook.github.io/react/img/logo.svg",
      alt: "Reactjs",
      title: "Facebook",
      subtitle: "What are you doing?",
      date: new Date(),
      unread: 0,
    },
    {
      avatar: "https://facebook.github.io/react/img/logo.svg",
      alt: "Reactjs",
      title: "Facebook",
      subtitle: "What are you doing?",
      date: new Date(),
      unread: 0,
    },
    {
      avatar: "https://facebook.github.io/react/img/logo.svg",
      alt: "Reactjs",
      title: "Facebook",
      subtitle: "What are you doing?",
      date: new Date(),
      unread: 0,
    },
    {
      avatar: "https://facebook.github.io/react/img/logo.svg",
      alt: "Reactjs",
      title: "Facebook",
      subtitle: "What are you doing?",
      date: new Date(),
      unread: 0,
    },
  ]);
  return (
    <ChannelContext.Provider value={[channels, setChannels]}>
      {props.children}
    </ChannelContext.Provider>
  );
};
