import React, { Component } from "react";
import { Navbar } from "react-chat-elements";
import { ChatList } from "react-chat-elements";
import { Popup } from "react-chat-elements";
import { SideBar } from "react-chat-elements";
import { Dropdown } from "react-chat-elements";
import { MessageBox } from "react-chat-elements";
import { Input } from "react-chat-elements";
import Button from "react-bootstrap/Button";

export default class Test extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: true,
    };
  }

  state = { show: true };

  render() {
    return (
      <div>
        <Input
          placeholder="Type here..."
          multiline={true}
          rightButtons={
            <Button color="white" backgroundColor="black" text="Send" />
          }
        />
        <MessageBox
          reply={{
            photoURL: "https://facebook.github.io/react/img/logo.svg",
            title: "elit magna",
            titleColor: "#8717ae",
            message: "Aliqua amet incididunt id nostrud",
          }}
          onReplyMessageClick={() => console.log("reply clicked!")}
          position={"right"}
          type={"text"}
          text={
            "Tempor duis do voluptate enim duis velit veniam aute ullamco dolore duis irure."
          }
        />
        <MessageBox
          position={"left"}
          type={"photo"}
          text={"react.svg"}
          data={{
            uri: "https://facebook.github.io/react/img/logo.svg",
            status: {
              click: false,
              loading: 0,
            },
          }}
        />
        <Dropdown
          buttonProps={{
            text: "Dropdown",
          }}
          items={[
            {
              text: "lorem",
            },
            {
              text: "ipsum",
            },
            {
              text: "dolor",
            },
          ]}
        />
        <SideBar
          top={<div>'TOP' area</div>}
          center={<div>'CENTER' area</div>}
          bottom={<div>'BOTTOM' area</div>}
        />
        <Navbar
          left={<div>'LEFT' area</div>}
          center={<div>'CENTER' area</div>}
          right={<div>'RIGHT' area</div>}
        />
        <ChatList
          className="chat-list"
          dataSource={[
            {
              avatar: "https://facebook.github.io/react/img/logo.svg",
              alt: "Reactjs",
              title: "Facebook",
              subtitle: "What are you doing?",
              date: new Date(),
              unread: 0,
            },
            {
              avatar: "https://facebook.github.io/react/img/logo.svg",
              alt: "Reactjs",
              title: "Facebook",
              subtitle: "What are you doing?",
              date: new Date(),
              unread: 0,
            },
          ]}
        />
        <Popup
          show={this.state.show}
          header="Lorem ipsum dolor sit amet."
          headerButtons={[
            {
              type: "transparent",
              color: "black",
              text: "close",
              onClick: () => {
                this.setState({ show: false });
              },
            },
          ]}
          text="Lorem ipsum dolor sit amet, consectetur adipisicing elit. Voluptatem animi veniam voluptas eius!"
          footerButtons={[
            {
              color: "white",
              backgroundColor: "#ff5e3e",
              text: "Vazgeç",
              onClick: () => {
                this.setState({ show: false });
              },
            },
            {
              color: "white",
              backgroundColor: "lightgreen",
              text: "Tamam",
              onClick: () => {
                this.setState({ show: false });
              },
            },
          ]}
        />
      </div>
    );
  }
}
