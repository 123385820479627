import React, { Component } from "react";
import { BrowserRouter as Router, Route,Routes } from "react-router-dom";
import App from "./pages/app/App";
import Base from "./components/Base";
import Test from "./pages/test/Test";
import "react-chat-elements/dist/main.css";
import appReducers from "./state/reducers/AppReducers";
import { Provider } from "react-redux";
import { createStore } from "redux";
import axios from "axios";
import MyChat from "./pages/chat/MyChat";
import t2 from "./pages/test2/t2";

let store = createStore(appReducers);

class AppRoutes extends Component {
  findUser = () => {
    if (window.sessionStorage.getItem("user") === null) {
      axios.get("/api/userinfo").then((res) => {
        console.log("result : " + res.data);
        if (res.data == null) {
        } else {
          window.sessionStorage["email"] = res.data["providerId"];
          window.sessionStorage["user"] = res.data["fullName"];
          window.sessionStorage["avatarUrl"] = res.data["avatarUrl"];
        }
      });
    }
  };

  render() {
    this.findUser();
    return (
      <Provider store={store}>
        <Router>
          <Routes>
            <Route path="/" element={<Base/>} />
            <Route path="/chat" element={<MyChat/>} />
            <Route path="/test" element={<Test/>} />
            <Route path="/app" element={<App/>} />
            <Route path="/t2" element={<t2/>} />
           </Routes>
        </Router>
      </Provider>
    );
  }
}

export default AppRoutes;
